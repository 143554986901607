<template>
  <div class="row">
    <div class="col-sm-12 title-order">
      <h1 class="er-title mb-2500 fs-2500">{{ titrePage }}</h1>
    </div>
    <div class="col-sm-6 description-order">
      <h3 class="er-under-title">Votre portail évolue</h3>
      <div class="mt-2 bloc-texte">
        <p class="er-p mt-3">
          Portailpro.gouv continue de vous proposer des services
          <br />innovants et pensés pour simplifier vos démarches.<br />
        </p>
        <p class="er-p mt-4">Retrouvez très prochainement ici {{ nomPage }}.</p>
        <div class="mt-2500">
          <router-link
            :to="{ name: 'Tableau de bord' }"
            href="#"
            class="fr-btn fr-btn--lg fr-btn--secondary mr-2"
            title="Page précédente"
            >Retour au tableau de bord</router-link
          >
        </div>
      </div>
    </div>
    <div class="col-sm-6 image-order mb-7">
      <img
        class="prochainement-image"
        src="../assets/img/prochainement.svg"
        alt="image représentant un site web en chantier"
      />
    </div>
  </div>
</template>

<script>
import SkipLinkMixin from '../mixins/skip-link-mixin';
export default {
  name: 'Prochainement',
  mixins: [SkipLinkMixin],
  data() {
    return {
      titrePage: '',
      nomPage: ''
    };
  },
  computed: {
    pagePath: (_this = this) => _this.$route.query?.origin
  },
  mounted() {
    this.controlAndAapting(this.pagePath);
  },
  methods: {
    controlAndAapting(pagePath) {
      let control = true;

      if (!pagePath) {
        control = false;
      } else if (pagePath === 'gdr') {
        this.titrePage = 'Gestion des rôles';
        this.nomPage = 'la gestion des rôles';
      } else {
        control = false;
      }

      if (!control) {
        this.$router.push({ name: 'Tableau de bord' }).catch(() => {});
      }
    }
  }
};
</script>
<style lang="scss">
.mt-2500 {
  margin-top: 2.5rem;
}
.mb-2500 {
  margin-bottom: 2.5rem;
}
.mb-7 {
  margin-bottom: 7rem !important;
}
.er-title {
  color: $base-color;
  font-family: Marianne;
  font-size: 40px;
  font-weight: bold;
}
.er-under-title {
  color: $font-color-base;
  font-family: Marianne;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}
.er-p {
  font-family: Marianne;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #383838;
}

@media only screen and (max-width: 760px) {
  .er-title {
    font-size: 38px;
  }

  .bloc-texte {
    order: 2;
    width: 100%;
    max-width: 100%;
    padding-bottom: 1rem;
  }

  .mb-7 {
    margin-bottom: 3rem !important;
  }

  .fr-col-6 {
    max-width: unset;
  }

  .fr-grid-row {
    display: contents;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
  }

  .title-order {
    order: 1;
  }

  .description-order {
    order: 3;
  }

  .image-order {
    order: 2;
  }

  .prochainement-image {
    width: 85%;
  }
}
</style>
